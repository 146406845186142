import React from "react";

import success_img from '../assets/img_success.webp';
import logo from '../assets/motusLogo.png';

const Success = ({ onclick }) => {
  return (
    <div>
      <nav>
        <div className="motusLogo">
          <img src={logo} alt="motusLogo" />
        </div>
      </nav>
      <div className="content filling">
        <div className="example">
          <img src={success_img} alt="" width="550" />
        </div>
        <div className="text">
          <div>
            <div className="instruction">Your upload was successful!</div>
          </div>
          <div className="submitbtn" data-testid="view-result" onClick={onclick}>
            <div className="btn">
              <div className="getMyFreeReport">View my results</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Success;