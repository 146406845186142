import React from "react";
import { SeeMoreButton } from "./button";

function calculate_time(data, target_name, datarate) {
  let seconds = 0;
  for (const element of data) {
    if (element[2] === target_name) {
      const start = element[0];
      const end = element[1];
      let time = (end - start) / datarate;
      if (time === 0) {
        time = 1;
      }
      seconds += time;
    }
  }
  seconds = Number(seconds.toFixed(1));
  return seconds > 1 ? `${seconds} seconds` : `${seconds} second`;
}

const Interpretation = ({ result, show_hrv }) => {
  let { arrhythmia, hr } = result;

  if (result["result"] === "success") {
    arrhythmia = arrhythmia.arrhythmia;
    hr = hr.heartRateSequence;
    const tachycardia = hr.reduce((total, next) => next[3] === "Tachycardia" ? total + 1 : total, 0);
    const bradycardia = hr.reduce((total, next) => next[3] === "Bradycardia" ? total + 1 : total, 0);

    const has_VPC = arrhythmia.filter((elem) => elem[2] === "VE Premature").length > 0;
    const has_VPC_Couplet = arrhythmia.filter((elem) => elem[2] === "VE Couplet").length > 0;
    const Bigeminy = calculate_time(arrhythmia, "Bigeminy", result.data_rate);
    const Trigeminy = calculate_time(arrhythmia, "Trigeminy", result.data_rate);
    const uninterpretable = calculate_time(arrhythmia, "Uninterpretable", result.data_rate);
    return (
      <div>
        <h2 className="Heart_title">Arrhythmia and overall interpretation</h2>
        <table>
          <tbody>
            <tr>
              <td>Tachycardia (Heart rate &gt; {100} BPM)</td>
              <td className="text_right">{tachycardia}/{hr.length}</td>
            </tr>
            <tr>
              <td>Bradycardia (Heart rate &lt; {60} BPM)</td>
              <td className="text_right">{bradycardia}/{hr.length}</td>
            </tr>
            <tr>
              <td>VPC</td>
              <td className="text_right">{has_VPC ? "+" : "-"}</td>
            </tr>
            <tr>
              <td>VPC Couplet</td>
              <td className="text_right">{has_VPC_Couplet ? "+" : "-"}</td>
            </tr>
            <tr>
              <td>Bigeminy</td>
              <td className="text_right">{Bigeminy}</td>
            </tr>
            <tr>
              <td>Trigeminy</td>
              <td className="text_right">{Trigeminy}</td>
            </tr>
            <tr>
              <td>HRV</td>
              <td className="text_right" id="hrv_button">
                <SeeMoreButton onClick={show_hrv} />
              </td>
            </tr>
            <tr>
              <td>Uninterpretable</td>
              <td className="text_right">{uninterpretable}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
  return (
    <div>
      <h2 className="Heart_title">Arrythmia and overall interpertation</h2>
    </div>
  );
}

export default Interpretation;