import React, { useState } from "react";

import Success from "../components/success";
import Watermark from "../components/watermark";
import ECGImage from "./ecg_image";
import Interpretation from "../components/interpretation";
import Colorbar from "../components/colorbar";
import { PredictionModal, PredictionTable } from "../components/prediction";
import Detail, { HRVTable } from "../components/detail";
import { OpenCloseButton } from "../components/button";

import "../style/material_icons.css";
import "../style/dashboard.css";
import logo from "../assets/motusLogo.png";
import backButton from "../assets/back_button.png";

const Dashboard = ({ data, handler, ecg }) => {

  // hide_result: whether to show dashboard.
  const [hide_result, setHideResult] = useState(true);
  // show_hrv: whether to show HRV detail table.
  const [show_hrv, setShowHRV] = useState(false);
  // show_hf: whether to show Heart Failure prediction.
  const [show_hf, setShowHF] = useState(false);
  const [show_sepsis, setShowSepsis] = useState(false);
  const [show_pain, setShowPain] = useState(false);
  // show_ecg: whether to show ECG waveform.
  const [show_ecg, setShowECG] = useState(false);
  // show_detail: whether to show response details.
  const [show_detail, setShowDetail] = useState(false);
  const [print_all, setPrintAll] = useState(false);

  const render_dashboard = () => {
    return (
      <React.Fragment>
        <Watermark />
        <nav>
          <div className="back">
            <img src={backButton} alt="prev page" onClick={handler} />
          </div>
          <div className="motusLogo">
            <img src={logo} alt="motusLogo" />
          </div>
        </nav>
        <article>
          <div className="dropdown" style={{ float: "right" }}>
            <button>
              <span className="btn_text">Export</span> <span className="material-icons">arrow_drop_down</span>
            </button>
            <div className="dropdown-content">
              <p>ECG waveform</p>
              <span id="show-all-ecg"
                onClick={() => { setShowECG(true); setPrintAll(true); }}
                style={print_all ? { color: "gray" } : {}}
              >Show all<br></br>(might be slow)</span>
              <span id="reset-ecg"
                onClick={() => { setShowECG(true); setPrintAll(false); }}
                style={!print_all ? { color: "gray" } : {}}
              >Reset</span>
              <hr></hr>
              <p>Export</p>
              <span id="export-pdf" onClick={() => { window.print(); setPrintAll(false); }}>PDF</span>
            </div>
          </div>
          <div className="db">
            <section className="print-friendly">
              <div className="dib section_width">
                <h2>Heart rhythm / pattern analysis</h2>
                <Colorbar result={data} name="heart_rate" />
                <Colorbar result={data} name="breath_rate" />
                <Colorbar result={data} name="dispersion" />
                <Interpretation result={data} show_hrv={() => setShowHRV(!show_hrv)} />
                <div className="print-only">
                  <h2> HRV (Heart Rate Variability) </h2>
                  <HRVTable hrv={data.hrv.hrv} />
                </div>
                <div className="modal" style={show_hrv ? { display: "block" } : { overflow: "hidden", height: 0 }}>
                  <div className="modal-content">
                    <h2>
                      HRV (Heart Rate Variability)
                      <span onClick={() => setShowHRV(!show_hrv)} className="close">&times;</span>
                    </h2>
                    <HRVTable hrv={data.hrv.hrv} />
                  </div>
                </div>
              </div>

              <aside className="dib" id="model-predictions">
                <div>
                  <div className="print-only">
                    <PredictionTable
                      result={data}
                      name="heart_failure"
                      windowSize={0.5}
                      stepSize={0.5}
                      handle_show_more={() => setShowHF(!show_hf)}
                      show_more={show_hf}
                    />
                  </div>
                  <div className="print-only">
                    <PredictionTable
                      result={data}
                      name="sepsis"
                      windowSize={5}
                      stepSize={1}
                      handle_show_more={() => setShowSepsis(!show_sepsis)}
                      show_more={show_sepsis}
                    />
                  </div>
                  <div className="print-only">
                    <PredictionTable
                      result={data}
                      name="pain"
                      windowSize={2}
                      stepSize={1}
                      handle_show_more={() => setShowPain(!show_pain)}
                      show_more={show_pain}
                    />
                  </div>
                  <div className="non-printable">
                    <PredictionModal
                      result={data}
                      name="heart_failure"
                      windowSize={0.5}
                      stepSize={0.5}
                      handle_show_more={() => setShowHF(!show_hf)}
                      show_more={show_hf}
                    />
                    <PredictionModal
                      result={data}
                      name="sepsis"
                      windowSize={5}
                      stepSize={1}
                      handle_show_more={() => setShowSepsis(!show_sepsis)}
                      show_more={show_sepsis}
                    />
                    <PredictionModal
                      result={data}
                      name="pain"
                      windowSize={2}
                      stepSize={1}
                      handle_show_more={() => setShowPain(!show_pain)}
                      show_more={show_pain}
                    />
                  </div>
                </div>
              </aside>
            </section>
          </div>
          <section className="db print-friendly" id="ecg_image">
            <OpenCloseButton
              title="ECG Waveform"
              onClick={() => { setShowECG(!show_ecg) }}
              show_state={show_ecg} />
            <ECGImage
              name="normal"
              ecg={ecg}
              data_rate={data.data_rate}
              show_state={show_ecg}
              plot_all={print_all}
            />
          </section>
          <section className="db p_display_none" id="detail">
            <OpenCloseButton
              title="Details"
              onClick={() => { setShowDetail(!show_detail) }}
              show_state={show_detail} />
            <Detail result={data} show_detail={show_detail} />
          </section>
        </article>
      </React.Fragment >
    )
  }

  return hide_result ?
    <Success onclick={() => setHideResult(false)} /> : render_dashboard();
}

export default Dashboard;