import React from "react";

import logo from '../assets/motus-logo-black.webp';

const Watermark = () => {
  return (
    <div className="watermark">
      <img src={logo} alt="" align="center" />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <img src={logo} alt="" align="center" />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <img src={logo} alt="" align="center" />
    </div>
  )
}

export default Watermark;