import axios from 'axios';


export const processFile = (filename, selectedFile) => {
    const extension = filename.split(".").pop();
    let promise;
    if (extension === "json") {
        promise = processJSONFile(selectedFile);
    } else if (extension === "txt") {
        promise = processTextFile(selectedFile);
    } else {
        return null;
    }
    return promise;
}

const processJSONFile = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener("load", (event) => {
            resolve(JSON.parse(event.target.result));
        });
        reader.readAsText(file);
        reader.onerror = (e) => { reject(e); };
    })
}

const processTextFile = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener("load", (event) => {
            const text = event.target.result;
            const data = {
                "eCG_data": Array.from(text.substring(1, text.length - 1).split(','), Number),
                "dataRate_Hz": 125,
                "increment_hrv": 60,
                "increment_hr": 10
            };
            resolve(data);
        });
        reader.readAsText(file);
        reader.onerror = (e) => { reject(e); };
    })
}

export function time2str(t, tickfreq) {
    /** Convert argument (in samples) to a string in HH:MM:SS format */
    let ss, mm, hh, tstring;

    ss = Math.floor(t / tickfreq);
    mm = Math.floor(ss / 60); ss %= 60;
    hh = Math.floor(mm / 60); mm %= 60;
    if (ss < 10) { ss = '0' + ss; }
    if (mm < 10) { mm = '0' + mm; }
    if (hh < 10) { hh = '0' + hh; }
    tstring = hh + ':' + mm + ':' + ss;
    return tstring;
}

export function str2time(s, tickfreq) {
    // Convert string argument to time in samples
    let c, t;
    c = s.split(":");
    switch (c.length) {
        case 1:
            if (c[0] === "") { t = 0; }
            else { t = +c[0]; }
            break;
        case 2:
            t = 60 * c[0] + Number(c[1]);
            break;
        case 3:
            t = 3600 * c[0] + 60 * c[1] + Number(c[2]);
            break;
        default: t = 0;
    }
    let res = Math.round(t * tickfreq);
    if (isNaN(res)) {
        return 0;
    }
    return res
}


export function arrayMinMax(arr) {
    return arr.reduce(([min, max], val) => [Math.min(min, val), Math.max(max, val)], [
        Number.POSITIVE_INFINITY,
        Number.NEGATIVE_INFINITY,
    ]);
}

export const authenticate = (token) => {
    return axios.post("/auth", { "token": token });
}

const postECG = (endpoint, data, token) => {
    return axios.post(endpoint, data, { headers: { "Authorization": token } });
}


export default postECG
