import React from "react"

export const OpenCloseButton = ({ title, title_style, onClick, show_state }) => {
  const icon = show_state ?
    <span className="material-icons non-printable" data-html2canvas-ignore="true">expand_less</span> :
    <span className="material-icons non-printable" data-html2canvas-ignore="true">expand_more</span>
  return (
    <div
      className="title_button"
      id={title}
      onClick={onClick}
    >
      <h2 style={title_style}>
        {title}
      </h2>
      {icon}
    </div>
  )
}

export const SeeMoreButton = ({ onClick }) => {
  return (
    <button
      className="btn_secondary non-printable"
      data-html2canvas-ignore="true"
      style={{ float: "right" }}
      onClick={onClick}
    >
      See more<span className="material-icons">open_in_new</span>
    </button>
  )
}
