import React, { useEffect, useState } from 'react';

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js';

import { time2str, str2time, arrayMinMax } from './utils.js';

import '../style/ecg_image.css';

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale);

const LineChartConfig = (data_rate, min_x, max_x, min_y = -1, max_y = 1) => {
  return {
    responsive: true,
    borderWidth: 1.5,
    maintainAspectRatio: false,
    animation: false,
    parsing: true,
    normalization: true,
    spanGaps: true,
    scales: {
      x: {
        title: {
          display: true,
          text: "seconds",
        },
        type: 'linear',
        min: min_x,
        max: max_x,
        ticks: {
          stepSize: data_rate * 0.2,
          callback: (value, index, ticks) => {
            const second = Number.parseFloat(value / data_rate).toFixed(1).toString();
            const is_int = ((value / data_rate) % 1) === 0;
            return is_int ? second.slice(0, second.length - 2) : "";
          },
        },
        grid: {
          color: "rgba(255, 0, 0, 0.5)",
          lineWidth: (context) => {
            return (context.tick.value / data_rate) % 1 === 0 ? 2 : 0.5;
          },
          drawTicks: false,
        }
      },
      y: {
        title: {
          display: true,
          text: "mV",
        },
        min: min_y,
        max: max_y,
        grid: {
          color: "rgba(255, 0, 0, 0.5)",
          drawTicks: false,
        }
      },
    },
    elements: {
      point: { radius: 0 }
    },
    interaction: {
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      decimation: {
        enabled: true
      }
    },
  }
};



const ECGImage = ({ name, ecg, data_rate, show_state, plot_all = false }) => {

  const [start, setStart] = useState(0);
  const [is_start, setIsStart] = useState(true);
  const [is_end, setIsEnd] = useState(false);
  const [nrows, setNumRows] = useState(3);
  const step = 10 * data_rate; // 10 seconds

  useEffect(() => {
    const num_rows = plot_all ? Math.round(ecg.length / step) : 3;
    setNumRows(num_rows);
  }, [plot_all, ecg.length, step]);

  const goToSpecificWindow = (target_sample) => {
    const last_start = Math.max(0, ecg.length - step);
    target_sample = Math.max(0, target_sample);
    target_sample = Math.min(target_sample, last_start);
    setIsStart(target_sample === 0);
    setIsEnd(target_sample === last_start);
    setStart(target_sample);
  };
  const goToNext = () => goToSpecificWindow(start + nrows * step);
  const goToPrevious = () => goToSpecificWindow(start - nrows * step);
  const goToStart = () => goToSpecificWindow(0);
  const goToEnd = () => goToSpecificWindow(Math.max(0, ecg.length - step));
  const goToWindow = () => {
    const target = document.getElementById(`${name}-t0`).value;
    goToSpecificWindow(str2time(target, data_rate));
  };

  const prepare_signal_data = (start_time, end_time) => {
    const len = end_time - start_time;
    const time_points = Array.from({ length: len }, (_, i) => start_time + i);
    return {
      labels: time_points,
      datasets: [
        {
          data: ecg.slice(start_time, end_time + 1),
          borderColor: "rgb(0, 0, 0, 1.0)"
        }
      ]
    }
  };

  let charts = [];
  const [minY, maxY] = arrayMinMax(ecg);
  for (let i = 0; i < nrows; i++) {
    const sig = prepare_signal_data(
      start + step * i,
      start + step * (i + 1)
    );
    const config = LineChartConfig(
      data_rate,
      start + step * i,
      start + step * (i + 1),
      minY,
      maxY
    );
    charts.push(
      <div id={"viewport" + i} className="ecgplot" key={i}>
        <Line data={sig} options={config} />
      </div>
    )
  }

  return (
    <div style={show_state ? { display: "block" } : { overflow: "hidden", height: 0 }}>
      <div id="plotdata">{charts}</div>
      <div id="view" className="non-printable">
        <div className="waveform-window" id="go-to-window">
          Go to:
          <input type="text"
            defaultValue=""
            id={`${name}-t0`}
            title="Enter the start time for the signal window"
            placeholder="HH:MM:SS" size="10"></input>
          <button className="w3-button" ctitle="go to specific signal window" onClick={goToWindow} data-testid="goto">Go</button>
        </div>

        <div className="waveform-window" id="advance-window">
          <div style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
            <button className="w3-button" title="start of record" disabled={is_start ? "disabled" : ""} onClick={goToStart}>{'\u23EE'}</button>
            <button className="w3-button" title="previous window" disabled={is_start ? "disabled" : ""} onClick={goToPrevious}>{'\u25C0'}</button>
            <button className="w3-button" title="next window" disabled={is_end ? "disabled" : ""} onClick={goToNext}>{'\u25B6'}</button>
            <button className="w3-button" title="end of record" disabled={is_end ? "disabled" : ""} onClick={goToEnd}>{'\u23ED'}</button >
          </div>
        </div>

        <div className="waveform-window" id="current-window" data-testid="current-window">
          <span>Start: {time2str(start, data_rate)}</span>
          <br></br>
          <span>End: {time2str(start + nrows * step, data_rate)}</span>
        </div>
      </div>
    </div>
  )
}

export default ECGImage;