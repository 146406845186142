import React, { useEffect, useState } from 'react';
import postECG, { authenticate, processFile } from './utils';

import '../style/file_uploader.css';
import '../style/loader.css';
import logo from '../assets/motusLogo.png';


const FileUploader = ({ handler, sampleData, sampleECG }) => {

  const [filename, setFilename] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [token, setToken] = useState("")

  useEffect(() => {
    const listener = function (e) {
      if (typeof e.data === 'string' && e.origin !== window.location.origin) {
        const jwt = e.data;
        authenticate(jwt)
          .then((resp) => { setToken(resp.data); })
          .catch((err) => { alert(err); })
      }
    };
    window.addEventListener("message", listener);
    if (window.parent !== window.self) {
      window.parent.postMessage("init", "*");
    }
    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);


  const setFile = (event) => {
    const input = event.target;
    setFilename(input.files[0].name)
    setSelectedFile(input.files[0])
  }

  const openDialog = () => {
    document.getElementById("my_ecg_file").click();
  }

  const clickButton = () => {
    document.getElementById("submit_button").click();
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setUploading(true);
    const promise = processFile(filename, selectedFile);
    if (promise === null) {
      setUploading(false);
      alert("Empty file!");
      return;
    }
    promise.then((data) => {
      postECG("/motus/v1/analysis", data, token)
        .then((res) => {
          res.data.data_rate = data["dataRate_Hz"];
          handler(res.data, data["eCG_data"]);
        }).catch((err) => {
          setUploading(false);
          alert(err);
        });
    }).catch((err) => {
      setUploading(false);
      alert(err);
    })
  };

  return uploading ? <Loading /> : (
    <div>
      <nav>
        <div className="motusLogo">
          <img src={logo} alt="motusLogo" />
        </div>
      </nav>
      <div className="content filling">
        <div className="text">
          <div className="title"> Upload your ECG file </div>
          <form
            id="upload_ecg"
            encType="multipart/form-data"
            acceptCharset="utf-8"
            onSubmit={handleSubmit}
          >
            <div className="instruction">
              Click the button below; <br /><br />
              Browse and select the ECG File in your computer.
              <br />
              Or
              <br />
              See our <span onClick={() => handler(sampleData, sampleECG)} className="instruction-link">Example</span>
            </div>
            <div className="inline">
              <div className="btn_choose" id="file-upload" onClick={openDialog}>
                <input
                  id="my_ecg_file"
                  type="file"
                  accept="text/plain,application/json"
                  name="file"
                  aria-label="file"
                  onChange={(e) => setFile(e)}
                  hidden
                />
                <div className="choose">Choose a File</div>
              </div>
              <p id="file-upload-filename" data-testid="file-upload-filename"> {filename} </p>
            </div>
            <div className="submitbtn" onClick={clickButton}>
              <div className="btn">
                <input id="submit_button" type="submit" value="Submit" data-testid="submit-button" hidden />
                <div className="getMyFreeReport">Submit</div>
              </div>
            </div>
          </form>
        </div>
      </div >
    </div >
  )
}

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading">
        Analyzing
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
      </div>
      <img src={logo} alt="" />
    </div>
  )
}

export default FileUploader;