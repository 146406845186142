import React from "react";

const Colorbar = ({ result, name }) => {
  const NAME_MAP = {
    dispersion: "Dispersion",
    heart_rate: "Heart Rate",
    breath_rate: "Respiratory Rate",
  };
  const UNIT_MAP = {
    dispersion: "",
    heart_rate: "BPM",
    breath_rate: "breaths/min",
  };
  const KEY_MAP = {
    dispersion: "dispersion_au",
    heart_rate: "heartRate_BPM",
    breath_rate: "breathRate_BPM",
  };
  const VALUE_RANGE = {
    dispersion: [0.05, 0.1, 0.2],
    heart_rate: [60, 100, 150],
    breath_rate: [12, 25, 30],
  }

  const get_percentage = (value, vmin, vmax) => {
    // translate(160 ~ 1390)
    if (value < vmin) {
      return 0;
    } else if (value > vmax) {
      return 100;
    }
    return 100 * (
      (value - vmin) /
      (vmax - vmin)
    );
  }

  if (name in result) {
    const value = result[name][KEY_MAP[name]];
    const value_name = NAME_MAP[name];
    const unit = UNIT_MAP[name];
    const range = VALUE_RANGE[name];
    return (
      <div>
        <h1>{value_name}: {value} {unit}</h1>
        <span>Low</span><span className="right">High</span>
        <div>
          <svg xmlns="https://www.w3.org/2000/svg" xmlnsXlink="https://www.w3.org/1999/xlink" width="100%"
            height="26" viewBox="0 0 1242 26">
            <defs>
              <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1"
                gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#f54291" />
                <stop offset="1" stopColor="#3c9be9" />
              </linearGradient>
            </defs>
            <g id="Group_95" dataname="Group 95" transform="translate(-158 -1033)">
              <rect id="Rectangle_140" dataname="Rectangle 140" width="24.625" height="1242" rx="12.312"
                transform="translate(1400 1034) rotate(90)" fill="url(#linear-gradient)" />
              <rect id="Rectangle_130" data-name="Rectangle 130" width="8" height="26" fill="#fff"
                transform={`translate(${1390 * get_percentage(value, 0, range[range.length - 1]) / 100} 1033)`}
              />
            </g>
          </svg>
        </div>
        <span>0 {unit}</span>
        {/* <span className="middle">{range[0]} {unit}</span>
        <span className="middle">{range[1]} {unit}</span> */}
        <span className="right"> {">"} {range[range.length - 1]} {unit}</span>
        <p></p>
        <span>Regular</span><span className="right">Irregular</span>
      </div>
    )
  }
  return (<div className="dib"></div>)
}

export default Colorbar;