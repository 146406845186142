import React, { useState } from 'react';
import Dashboard from './containers/dashboard';
import FileUploader from './containers/file_uploader';

import sampleECG from './samples/sample_ecg.json';
import sampleData from './samples/sample_output.json';

import './style/app.css';

const App = () => {

    const [is_complete, setComplete] = useState(false);
    const [data, setData] = useState(sampleData);
    const [ecg, setECG] = useState(sampleECG);

    const handleInit = () => {
        setComplete(false);
        setData(sampleData);
        setData(sampleECG);
    };

    const handleAnalysisComplete = (result, ecg_waveform) => {
        setComplete(true);
        setData(result);
        setECG(ecg_waveform);
    };

    return (
        <React.Fragment>
            {is_complete ?
                <Dashboard data={data} handler={handleInit} ecg={ecg} />
                : <FileUploader handler={handleAnalysisComplete} sampleData={sampleData} sampleECG={sampleECG} />
            }
        </React.Fragment>
    )
}

export default App;