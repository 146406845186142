import React from "react";

const Detail = ({ result, show_detail }) => {
    return (
        <div style={show_detail ? { display: "block" } : { overflow: "hidden", height: 0 }}>
            <p>Analysis result: {JSON.stringify(result.result)}</p>
            <p>{JSON.stringify(result.arrhythmia)}</p>
            <p>{JSON.stringify(result.hr)}</p>
        </div>
    )
}

export const HRVTable = ({ hrv }) => {
    const digit = 2;
    let hrv_rows = [];
    if (hrv) {
        for (let i = 0; i < hrv.length; i++) {
            const data = hrv[i][2];
            hrv_rows.push(
                <tr key={i}>
                    <td className="text_left">{`${hrv[i][0]} / ${hrv[i][1]}`}</td>
                    <td>{Number(data["mrri"].toFixed(digit))}</td>
                    <td>{Number(data["nn50"].toFixed(digit))}</td>
                    <td>{Number(data["pnn50"].toFixed(digit))}</td>
                    <td>{Number(data["rmssd"].toFixed(digit))}</td>
                    <td>{Number(data["sdnn"].toFixed(digit))}</td>
                    <td>{Number(data["hf"].toFixed(digit))}</td>
                    <td>{Number(data["lf"].toFixed(digit))}</td>
                    <td className="text_right">{Number(data["lf_over_hf"].toFixed(digit))}</td>
                </tr>
            );
        }
    }
    return (
        <div className="table-responsive">
            <table id="hrv-detail-table">
                <thead>
                    <tr>
                        <th className="text_left">Start / End</th>
                        <th>mrri</th>
                        <th>nn50</th>
                        <th>pnn50</th>
                        <th>rmssd</th>
                        <th>sdnn</th>
                        <th>hf</th>
                        <th>lf</th>
                        <th className="text_right">lf/hf</th>
                    </tr>
                </thead>
                <tbody>
                    {hrv_rows}
                </tbody>
            </table>
        </div>
    )
}

export default Detail;