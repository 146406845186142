// Showing Heart Failure or Sepsis model predictions
import React from "react";
import { SeeMoreButton } from "./button";

const NAME_MAP = {
  heart_failure: "Heart failure",
  sepsis: "Sepsis",
  pain: "Pain",
  lvef: "LVEF",
};

const get_prediction_table = (data, windowMinutes, stepMinutes) => {
  let rows = [];
  for (let i = 0; i < data.length; i++) {
    let prediction = "+";
    if (data[i] === 0) {
      prediction = "-";
    }
    rows.push(
      <tr key={i}>
        <td>{`${i * stepMinutes}-${i * stepMinutes + windowMinutes} min`}</td>
        <td className="text_right">{prediction}</td>
      </tr>
    );
  }

  return (
    <table className="text_center">
      <thead>
        <tr>
          <th>Time window</th>
          <th className="text_right">Prediction</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  );
}

const calculate_positive_percentage = (data) => {
  const positive = data.reduce((total, nxt) => total + nxt, 0);
  return Math.round((positive / data.length) * 100);
}

const get_percentage_color = (percent) => {
  let color = "#19D83E"; // green
  if (percent > 70) {
    color = "#FD502E"; // red
  } else if (percent > 40) {
    color = "#FF8C29"; // oramge
  }
  return color;
}

export const PredictionModal = ({ result, name, handle_show_more, show_more, windowSize, stepSize }) => {

  if ((name in result) && (result[name].predictions.length > 0)) {
    const percentage = calculate_positive_percentage(result[name].predictions);
    const font_color = get_percentage_color(percentage);
    const prediction_table = get_prediction_table(result[name].predictions, windowSize, stepSize);

    return (
      <div className="db card card_s">
        <h1>{NAME_MAP[name]} possibility</h1>
        <h2 className="text_left">
          <span style={{ color: font_color }}>{percentage}%</span>
          <SeeMoreButton onClick={handle_show_more} />
        </h2>
        <div className="modal" style={show_more ? { display: "block" } : { display: "none" }}>
          <div className="modal-content">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h2>{NAME_MAP[name]}</h2>
              <span onClick={handle_show_more} className="close">&times;</span>
            </div>
            {prediction_table}
          </div>
        </div>
        {prediction_table}
      </div>
    )
  }
  return (<React.Fragment></React.Fragment>)
}

export const PredictionTable = ({ result, name, windowSize, stepSize }) => {

  if ((name in result) && (result[name].predictions.length > 0)) {
    const percentage = calculate_positive_percentage(result[name].predictions);
    const font_color = get_percentage_color(percentage);
    const prediction_table = get_prediction_table(result[name].predictions, windowSize, stepSize);

    return (
      <React.Fragment>
        <h1>{NAME_MAP[name]} possibility</h1>
        <h2>
          <span style={{ color: font_color }}>{percentage}%</span>
        </h2>
        {prediction_table}
      </React.Fragment>
    )
  }
  return (<React.Fragment></React.Fragment>)

}
